<template>
  <profile
    ref="profile"
    :username="userData.username"
    active-tab="calendar"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Profile from './Profile.vue';

export default defineComponent({
  components: {
    Profile,
  },
  mounted() {
    (this.$refs.profile as InstanceType<typeof Profile>).loadUser();
  },
});
</script>
