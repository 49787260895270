
import { defineComponent } from 'vue';
import Profile from './Profile.vue';

export default defineComponent({
  components: {
    Profile,
  },
  mounted() {
    (this.$refs.profile as InstanceType<typeof Profile>).loadUser();
  },
});
